<template>
  <b-card-code title="Create supplier">
    <b-card-text>
      <span>Please enter supplier details</span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Supplier"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
              >
                <b-form-input
                  v-model="supplier.company_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Supplier name ..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Address"
            >
              <validation-provider
                #default="{ errors }"
                name="Address"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="supplier.address"
                  placeholder="Textarea"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email"
              >
                <b-form-input
                  id="email"
                  v-model="supplier.email"
                  placeholder="Email address"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addSupplier"
            >
              Create
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      supplier: {
        company_name: '',
        email: '',
        address: '',
      },
      required,
    }
  },

  methods: {
    async addSupplier() {
      try {
        await axiosIns.post('/suppliers/create/', this.supplier)
        this.$router.push({ name: 'suppliers' })
        this.showToast('success', 'top-center', 'Supplier successfully created')
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
      }
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
